/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.LoginAccount {

    &-InnerWrapper{
        @include mobile{
            .Form{
                margin-top: 130px;
                text-align: center;
                .MyAccountOverlay-ForgotPassword {
                    margin-top: 10px;
                }
            }
        }
    }
    @include mobile {
        .WebsiteSwitch {
            margin: 10px 0 25px;
            text-align: center;
        }
    }
}