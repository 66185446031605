/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.MyAccount {
    @include mobile {
        &-Wrapper {
            .MyAccountTabList {
                &-ExpandableContentButton {
                    padding-left: 14px;
                    padding-right: 14px;
                }
            }
        }
        &-TabContent {
            margin-left: 10px;
            margin-right: 10px;
            .MyAccountDashboard-CustomerData {
                overflow-x: scroll;
            }
            .MyAccountDashboard-DefaultAddress {
                overflow-x: scroll;
            }
            .MyAccountDashboard-DefaultAddress {
                overflow-x: scroll;
            }
            .KeyValueTable {
                th {
                    min-width: 100px;
                    padding-right: 0 ;
                }
                td {
                    padding-left: 0;
                }
            }
        }
    }
    @media (max-width: 374px) {
        &-TabContent {
            // margin-left: 5px;
            // margin-right: 5px;
            .KeyValueTable {
                font-size: 13px;
            }
        }
    }
}