/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.MyAccountOverlay {
    &-Additional_state_signIn {
        .Button-likeLink {
            padding-left: 5px ;
            margin-bottom: 1px;
            &:hover {
                color: var(--link-hover);
            }
        }
        .Button_likeLink {
            padding-left: 0;
            margin-bottom: 1px;
            font-weight: 700;
            &:hover {
                color: var(--link-hover);
                text-decoration: none;
            }
        }
    }
}